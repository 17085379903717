import { createSlice } from "@reduxjs/toolkit";

import {
  editRequestService,
  requestVehicleDataCall,
  requestIsApprovedConfirmCall,
  requestIsCheckedConfirmCall,
  requestRemoveService,
  companyReqeustVehicleCall,
  requestRemoveVehicleService,
  inspectorSetVehicleCall,
  requestIsApprovedVehicleConfirmCall,
  cancelRequestListCall,
} from "../../utils/services/requestService";

import notify from "devextreme/ui/notify";
import { getVehicle } from "./vehicle";
export const slice = createSlice({
  name: "requestVehicle",
  initialState: {
    requestData: null,
    requestConfirmedData: null,
    requestSearchData: null,
    requestInspectionData: null,
    inspectorVehicleData: null,
    trailerInspectionData: null,
    filter: { is_approved: 0, is_requested: 0 },
    filterAdmin: { is_approved: 0, is_requested: 1 },
    filterConfirmed: { is_requested: 1 },
    filterConfirmedAdmin: { is_approved: 1 },
    isLoading: true,
  },

  reducers: {
    setFilter: (state, { payload }) => {
      //  console.log(payload);
      state.filter = payload;
    },
    setFilterConfirmed: (state, { payload }) => {
      // console.log(payload);
      state.filterConfirmed = payload;
    },
    setLoading: (state, { payload }) => {
      // console.log(payload);
      state.isLoading = payload;
    },
    setRequestSearchData: (state, { payload }) => {
      // console.log(payload);
      state.requestSearchData = payload;
    },
    setRequestData: (state, { payload }) => {
      // console.log(payload);
      state.requestData = payload;
    },
    setRequestIsCheckedData: (state, { payload }) => {
      let listCopy = [...state.requestData];
      listCopy.filter((item) => {
        if (item.id === payload.id) {
          item.is_checked = payload.is_checked;
        }
      });
    },
    setRequestConfirmedData: (state, { payload }) => {
      // console.log(payload);
      state.requestConfirmedData = payload;
    },
    setInspectorVehicleData: (state, { payload }) => {
      // console.log(payload);
      state.inspectorVehicleData = payload;
    },
    setRequestApprove: (state, { payload }) => {
      // console.log(payload);
      state.requestConfirmedData.unshift(payload);
    },
    setCanceledRequest: (state, { payload }) => {
      // console.log(payload);

      let index = state.requestConfirmedData.findIndex(
        ({ id }) => id === payload
      );
      state.requestConfirmedData.splice(index, 1);
    },
    setRemoveRequest: (state, { payload }) => {
      // console.log(payload);

      let index = state.requestData.findIndex(({ id }) => id === payload);
      state.requestData.splice(index, 1);
    },
  },
});

export const {
  setLoading,
  setRequestSearchData,
  setRequestData,
  setCreateRequest,
  setRemoveRequest,
  setRequestApprove,
  setEditRequest,
  setFilter,
  setFilterConfirmed,
  setRequestIsCheckedData,
  setRequestConfirmedData,
  setCanceledRequest,
  setInspectorVehicleData,
} = slice.actions;

export const requestIsApprovedVehCancel =
  (data) => async (dispatch, getState) => {
    try {
      // console.log(data);

      // console.log(data);
      const responseData = await requestIsApprovedVehicleConfirmCall(data);
      // console.log(responseData);
      if (responseData.status.code === 200) {
        // console.log("ffffffffffffffff");
        // console.log(responseData.data);

        dispatch(setCanceledRequest(data.id));
        dispatch(setRequestApprove(responseData.data));
        // dispatch(setRequestInspectionData(responseData.data));
      } else {
        notify(
          {
            message: responseData.status.message,
            width: 450,
          },
          "error"
        );
      }

      //console.log(alert);
    } catch (error) {
      console.log(error);
    }
  };
export const getRequest = (filter) => async (dispatch, getState) => {
  try {
    const responseData = await requestVehicleDataCall(filter);

    if (responseData.status.code === 200) {
      // console.log(responseData.data);

      dispatch(setRequestData(responseData.data));
      dispatch(setLoading(false));

      // dispatch(setRequestInspectionData(responseData.data));
    } else {
      /// console.log(responseData);
      notify(
        {
          message: responseData.status.message,
          width: 450,
        },
        "error"
      );
    }

    //console.log(alert);
  } catch (error) {
    console.log(error);
  }
};
export const companyReqeustVehicleFun =
  (data) => async (dispatch, getState) => {
    try {
      const responseData = await companyReqeustVehicleCall(data);

      if (responseData.status.code === 200) {
        // console.log(responseData.data);

        // dispatch(setRequestData(responseData.data));
        //  dispatch(setLoading(false));
        notify(
          {
            message: responseData.status.message,
            width: 450,
          },
          "success"
        );
        dispatch(getRequestConfirmed({ is_requested: 1 }));
        dispatch(getVehicle({ is_approved: 0, is_requested: 0 }));
        // dispatch(setRequestInspectionData(responseData.data));
      } else {
        /// console.log(responseData);
        notify(
          {
            message: responseData.status.message,
            width: 450,
          },
          "error"
        );
      }

      //console.log(alert);
    } catch (error) {
      console.log(error);
    }
  };
export const getRequestConfirmed =
  (filterConfirmed) => async (dispatch, getState) => {
    try {
      const responseData = await requestVehicleDataCall(filterConfirmed);

      if (responseData.status.code === 200) {
        console.log(responseData.data);

        dispatch(setRequestConfirmedData(responseData.data));
        dispatch(setLoading(false));

        // dispatch(setRequestInspectionData(responseData.data));
      } else {
        /// console.log(responseData);
        notify(
          {
            message: responseData.status.message,
            width: 450,
          },
          "error"
        );
      }

      //console.log(alert);
    } catch (error) {
      console.log(error);
    }
  };
export const inspectorVehicleSetFun =
  (filter) => async (dispatch, getState) => {
    try {
      const responseData = await inspectorSetVehicleCall(filter);

      if (responseData.status.code === 200) {
        // console.log(responseData.data);

        dispatch(setInspectorVehicleData(responseData.data));
        dispatch(setLoading(false));
        dispatch(getRequest({ is_approved: 0, is_requested: 1 }));
        notify(
          {
            message: responseData.status.message,
            width: 450,
          },
          "success"
        );
        // dispatch(setRequestInspectionData(responseData.data));
      } else {
        /// console.log(responseData);
        notify(
          {
            message: responseData.status.message,
            width: 450,
          },
          "error"
        );
      }

      //console.log(alert);
    } catch (error) {
      console.log(error);
    }
  };

export const createRequest = (formData, type) => async (dispatch, getState) => {
  try {
    const responseData = await editRequestService(formData);
    //  console.log(responseData);
    if (responseData.status.code === 200) {
      if (type === 2) {
        dispatch(setEditRequest(responseData.data[0]));
      } else {
        dispatch(setCreateRequest(responseData.data[0]));
      }

      notify(
        {
          message: responseData.status.message,
          width: 450,
        },
        "success"
      );
      // dispatch(setRequestInspectionData(responseData.data));
    } else {
      notify(
        {
          message: responseData.status.message,
          width: 450,
        },
        "error"
      );
    }

    //console.log(alert);
  } catch (error) {
    console.log(error);
  }
};
export const removeRequestList = (requestId) => async (dispatch, getState) => {
  try {
    const responseData = await requestRemoveVehicleService(requestId);
    if (responseData.status.code === 200) {
      // console.log(responseData.data);
      // dispatch(setRemoveRequest(requestId));
      notify(
        {
          message: responseData.status.message,
          width: 450,
        },
        "success"
      );
      dispatch(getRequestConfirmed({ is_approved: 1 }));
      dispatch(getRequest({ is_approved: 0, is_requested: 1 }));
    }
  } catch (error) {
    console.log(error);
  }
};
export const cancelRequestList = (data) => async (dispatch, getState) => {
  try {
    const responseData = await cancelRequestListCall(data);
    if (responseData.status.code === 200) {
      // console.log(responseData.data);
      // dispatch(setRemoveRequest(requestId));
      notify(
        {
          message: responseData.status.message,
          width: 450,
        },
        "success"
      );
      dispatch(getRequestConfirmed({ is_approved: 1 }));
      dispatch(getRequest({ is_approved: 0, is_requested: 1 }));
    }
  } catch (error) {
    console.log(error);
  }
};
export const removeRequestCompanyList =
  (requestId) => async (dispatch, getState) => {
    try {
      const responseData = await requestRemoveVehicleService(requestId);
      if (responseData.status.code === 200) {
        // console.log(responseData.data);
        // dispatch(setRemoveRequest(requestId));
        notify(
          {
            message: responseData.status.message,
            width: 450,
          },
          "success"
        );
        dispatch(getRequestConfirmed({ is_requested: 1 }));
        dispatch(getRequest({ is_approved: 0, is_requested: 0 }));
      }
    } catch (error) {
      console.log(error);
    }
  };
export const requestIsCheckedConfirm = (data) => async (dispatch, getState) => {
  try {
    // console.log(data);

    const responseData = await requestIsCheckedConfirmCall(data);
    // console.log(responseData);
    if (responseData.status.code === 200) {
      // console.log("ffffffffffffffff");
      //  console.log(responseData.data);
      dispatch(setRequestIsCheckedData(responseData.data));
      // dispatch(setRequestInspectionData(responseData.data));
      notify(
        {
          message: responseData.status.message,
          width: 450,
        },
        "success"
      );
    } else {
      notify(
        {
          message: responseData.status.message,
          width: 450,
        },
        "error"
      );
    }

    //console.log(alert);
  } catch (error) {
    console.log(error);
  }
};
export const requestIsApprovedConfirm =
  (reqId, note) => async (dispatch, getState) => {
    try {
      // console.log(data);
      const data = {
        id: reqId,
        note: "Батлав",
        is_approved: 1,
      };
      const responseData = await requestIsApprovedConfirmCall(data);
      // const responseVehData = await editVehicleServic(data);
      // console.log(responseData);
      if (responseData.status.code === 200) {
        // console.log("ffffffffffffffff");
        // console.log(responseData.data);

        dispatch(setRemoveRequest(reqId));
        dispatch(getRequest(getState.filter));
        dispatch(setRequestApprove(responseData.data));
        notify(
          {
            message: responseData.status.message,
            width: 450,
          },
          "success"
        );
        // dispatch(setRequestInspectionData(responseData.data));
      } else {
        notify(
          {
            message: responseData.status.message,
            width: 450,
          },
          "error"
        );
      }

      //console.log(alert);
    } catch (error) {
      console.log(error);
    }
  };

export default slice.reducer;
